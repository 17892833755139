<template>
    <v-container>
        <base-material-card icon="mdi-truck" title="Motivos de no visita" class="px-5 py-3">
            <v-row>
                <v-col cols="12">
                    <v-btn color="primary" @click="$router.push({name: 'MotivoNoVisitaCreate'})">Nuevo</v-btn>
                </v-col>
                <v-col cols="12">
                    <v-data-table :headers="headers" :items="impuestos" item-key="id" hide-default-footer disable-pagination class="elevation-1">
                        <template v-slot:item.accciones="{item}">
                            <v-btn fab icon x-small @click="$router.push({name: 'MotivoNoVisitaEdit',params: {id: item.id}})">
                                <v-icon>far fa-edit</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col col="12">
                    <v-pagination v-model="pagination.currentPage" class="my-4" :length="pagination.lastPage" @input="loadMotivos" :total-visible="10" color="primary"></v-pagination>
                </v-col>
            </v-row>
        </base-material-card>
    </v-container>
</template>
<script>
    export default {
        data: () => ({
            headers: [
                { text: 'Nombre', value: 'nombre', sortable: false },
                { text: 'Acciones', value: 'accciones', sortable: false },
            ],
            impuestos: [],
            pagination: {
                perPage: 10,
                lastPage: 1,
                currentPage: 1
            }
        }),
        mounted() {
            this.loadMotivoss()
        },
        methods: {
            loadMotivoss() {
                this.$http.get('/motivos_no_visitas/', {
                    params: {
                        page: this.pagination.currentPage
                    }
                }).then(response => {
                    this.impuestos = response.data.data;
                    this.pagination.lastPage = response.data.last_page;
                    this.pagination.currentPage = response.data.current_page;
                })
            }
        }
    }
</script>
